import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
export const baseURL = require("../../../framework/src/config.js");
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  noData: boolean;
  datacoming: any;
  selectsafeValues: string;
  tabValue: number;
  showSection: boolean;
  showSectionstill: boolean;
  AchievedStrtegies: boolean;
  open: boolean,
  openWell: boolean,
  showAchievedStrategy: any
  openCongratessafer: boolean;
  isPopupOpensafer: boolean;
  saferuserData: any;
  getachiveData: any;
  noDataFound: boolean,
  userName: any
  categories: any;
  achievedText: string;
  deleteModal: boolean,
  deleteData: {
    cardId: number | undefined
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaferuseStrategiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  deleteBulkUploadCallId: string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  saferuserStrategiesApiCallId: string = "";
  achieveStrategiesApiCallId: string = "";
  saferuserlastweekStrategiesApiCallId: string = ""
  deleteCategoryCallId: string = ""
  categoriesListApiCallId: string = "";
  scrollRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      selectsafeValues: "Alcohol",
      openCongratessafer: false,
      tabValue: 0,
      showSection: false,
      showSectionstill: true,
      AchievedStrtegies: false, open: false,
      openWell: false,
      showAchievedStrategy: null,
      isPopupOpensafer: false,
      saferuserData: [],
      getachiveData: [],
      noData: false,
      datacoming: [],
      noDataFound: false,
      userName: '',
      categories: [],
      achievedText: "",
      deleteModal: false,
      deleteData: {
        cardId: undefined
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && apiRequestCallId === this.saferuserStrategiesApiCallId) {
        this.saferuserStrategiesResponse(responseJson);
        if (responseJson.length === 0) {
          this.setState({ noData: true, datacoming:responseJson  });
          
        } else {
          this.setState({ noData: false });
        }
        console.log(this.state.noData ? "nodata" : "data", "handleChangeStehandleChangeSte");
      } else if (apiRequestCallId && apiRequestCallId === this.saferuserlastweekStrategiesApiCallId) {
        this.saferuserLastWeekStrategiesResponse(responseJson);
        if (responseJson.length === 0) {
          this.setState({ noData: true, datacoming:responseJson }, () => {

          });
        } else {
          this.setState({ noData: false });
        }
      } else if (apiRequestCallId && apiRequestCallId === this.achieveStrategiesApiCallId) {
        this.achieveStrategiesApiresponse(responseJson);
      } else if (apiRequestCallId && apiRequestCallId === this.deleteCategoryCallId) {
        this.getCategories();
      } else if (apiRequestCallId && apiRequestCallId === this.categoriesListApiCallId) {

        this.handleCategoriesList(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ saferuserData: [...this.state.saferuserData] });
    removeStorageData("selectedCardId");
    removeStorageData("selectedCategory");
    removeStorageData("weekValue");
    removeStorageData("goalValue");
    const selectsafeValues = await getStorageData("susSelectedCategory") || "";
    const values = await getStorageData("susWeekValue") || 0;
    this.getCategories();
    this.setState({
      userName: localStorage.getItem("username"),
      selectsafeValues,
      tabValue: Number(values),
    })
    setTimeout(() => {
      this.scrollRef.current &&
        this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.saferuserData !== this.state.saferuserData) {
      console.log("Updated saferuserData:", this.state.saferuserData);
    }
  }

  saferuserAPIStrategiesdData(newValues: any): boolean {
    // Customizable Area Start
    this.setState({ isLoading: true, saferuserData: [], selectsafeValues: newValues });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saferuserStrategiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saferuserstrategiesEndpoint}/safer_user_strategies?category=${newValues}&timezone=${timeZone}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: false })
    // Customizable Area End
    return true;
  }
  saferuserAPIlastweekStrategiesdData(newValues: any): boolean {
    // Customizable Area Start
    this.setState({ isLoading: true, saferuserData: [] });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saferuserlastweekStrategiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.lastweekdaferEndpoint}/safer_user_strategies/last_week_strategies?category=${newValues}&timezone=${timeZone}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: false })
    // Customizable Area End
    return true;
  }
  achieveStrategiesAPIData(cardId: any): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.achieveStrategiesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.achieveStrategiesEndpoint}/strategy_achieved?id=${cardId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getCategories = () => {
    this.setState({ isLoading: true, saferuserData: [], noDataFound: false, deleteData: { cardId: undefined }, deleteModal: false });
    let token = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stretagyCategoriesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: false })
  };

  handleCategoriesList = (responseJson: any) => {
    this.setState({ categories: responseJson.categories_list }, () => {
      const categoryArray = ["Alcohol", "Cannabis", "Tobacco or Nicotine", "Uppers", "Downers"];
      const matchedCategory = categoryArray.find(category => this.state.categories.includes(category));
      const isExist = this.state.categories.includes(this.state.selectsafeValues);
      if (this.state.tabValue === 0) {
        this.saferuserAPIStrategiesdData(isExist ? this.state.selectsafeValues : matchedCategory)
      } else {
        this.saferuserAPIlastweekStrategiesdData(isExist ? this.state.selectsafeValues : matchedCategory)
      }
    });
  };

  goToBackDashboards = () => {
    this.props.navigation.navigate("DashboardInformation")
  }

  handleOpensaferToggle = () => {
    this.setState({ openCongratessafer: true });
  };
  handleIconClicksafer = (id: any) => {
    this.setState((prevState) => ({
      isPopupOpensafer: prevState.isPopupOpensafer === id ? null : id,
    }), () => {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    });
  };

  handleClickOutsidePopup = (event: any) => {
    if (!event.target.closest('.popup-container')) {
      this.setState({ isPopupOpensafer: false }, () => {
        document.removeEventListener('mousedown', this.handleClickOutsidePopup);
      });
    }
  };

  handleClosesaferToggle = () => {
    this.setState({ openCongratessafer: false });
  };

  hamburgerMenusafestr() {
    localStorage.setItem("pageNavigate", "SaferuseStrategies")
    this.props.navigation.navigate("HamburgerMenu")
  }
  goTosubandcate() {
    /* istanbul ignore next */
    localStorage.setItem("NewUseFound", "No")
    setStorageData("BackToSaferuseStrategies", "SaferUserStrategies");
    removeStorageData("activeTab");
    removeStorageData("selectedSubstance");
    this.props.navigation.navigate("SaferUserStrategies")
  }
  handleStretigies = (event: any, newValues: any) => {
    setStorageData("susSelectedCategory", newValues)
    this.setState({
      isPopupOpensafer: false
    })
    if (this.state.tabValue === 0) {
      this.saferuserAPIStrategiesdData(newValues)
    } else {
      this.saferuserAPIlastweekStrategiesdData(newValues)
    }

    this.setState({ selectsafeValues: newValues });
  }
  handleChangeSte = (event: any, newValues: any) => {
    setStorageData("susWeekValue", newValues.toString());
    this.setState({ tabValue: newValues, }, () => {
    })
    console.log(this.state.tabValue, "handleChangeSte");

    this.getCategories();
  }

  handleToggle = (card: any) => {
    this.achieveStrategiesAPIData(card.id)
    this.setState({ showAchievedStrategy: card.id, openCongratessafer: true, achievedText: card.title });
  };

  handleClosewell = () => {
    this.setState({ openWell: false });
  };
  handleOpenwell = () => {
    this.setState({ openWell: true });
  };

  achieveStrategiesApiresponse = (responseJson: any) => {
    if (responseJson) {
      this.setState({ getachiveData: responseJson });
      if (this.state.tabValue === 0) {
        this.saferuserAPIStrategiesdData(this.state.selectsafeValues)
      } else {
        this.saferuserAPIlastweekStrategiesdData(this.state.selectsafeValues)
      }
    }
  }
  saferuserStrategiesResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if (responseJson) {
      if (Array.isArray(responseJson) && responseJson.length > 0) {
        this.setState({ saferuserData: responseJson, noDataFound: false });
        console.log(this.state.saferuserData, 'saferuserDatasaferuserData');
      } else {
        this.setState({ saferuserData: [], noDataFound: true });
      }
    }
  }

  saferuserLastWeekStrategiesResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if (responseJson) {
      if (responseJson.length > 0) {
        this.setState({ saferuserData: responseJson, noDataFound: false });
        console.log(this.state.saferuserData, 'saferuserDatasaferuserData');

      } else {
        this.setState({ saferuserData: [], noDataFound: true });
      }
    }
  }

  handleEditSaferUserData = (cardId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SaferUserStrategies");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    setStorageData("selectedCardId", cardId);
  };
  handleDeleteSaferUserData(cardId: number) {
    let data = localStorage.getItem("token") || "{}"
    const header = {
      token: data
    };

    const deleteCategory = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoryCallId = deleteCategory.messageId;

    deleteCategory.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaferUserApiEndPoint}/${cardId}`
    );

    deleteCategory.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteCategory.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeletesaferUserAPiMethod
    );


    runEngine.sendMessage(deleteCategory.id, deleteCategory);

    return true;
  }

  openDelteModal = (data: any) => {
    this.setState({ deleteModal: true, deleteData: data })

  }
  closeDeleteModal = () => {
    this.setState({ deleteModal: false })
  }
  onStatergyDelete = () => {
    const { cardId } = this.state.deleteData
    if (cardId) {
      this.handleDeleteSaferUserData(cardId)
    }
  }

  // Customizable Area End
}