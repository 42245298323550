import React from 'react';
import {
    Box,
    Typography,
    Radio, FormControlLabel,
    FormControl,
    IconButton,
    TextField,
    Button,
    CardActions,
    Card,
    Backdrop
} from "@mui/material";
import DatePickerAlcohol from '../../landingpage/src/DatePickerAlcohol.web';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AlcohalSubstanceController, {
    Props,
} from './AlcohalSubstanceController.web';
import { info,  calendar, arrowright } from './assets';
import moment from 'moment';




export const configJSON = require("./config.js");


export default class AlcohalSubstance extends AlcohalSubstanceController {
    constructor(props: Props) {
        super(props);

    }
    doesMatchFirstFive = () => {
        return this.state?.optionsDate
            .slice(0, 5)
            .some((opt: any) => opt.date === moment(this.state?.formattedDateInter).format('YYYY-MM-DD'));
    }
    shouldSelectEmptyDate = (doesMatchFirstFive: any) => {

        return !doesMatchFirstFive && this.state?.formattedDateInter != null && this.state?.formattedDateInter != 'no';
    }
    isValidText = () => {
        return /^[^a-zA-Z]*$/.test(this.state.OthersResponse || '');
    }

    getSelectedValueComponent = (selectedValue: string) => {
        const { drinkCount } = this.state;
        const isValidText = this.isValidText()

        switch (selectedValue) {
            case 'Reduce how much':
                return (<>
                    <Typography sx={{

                        fontSize: '16px',
                        marginBottom: '20px',
                        color: '#252525',
                        width: '90%',
                        fontFamily: 'Chromatica-Regular',
                        padding: '10px',
                        borderRadius: '16px',
                        backgroundColor: '#F5F5F5',
                        marginTop: '20px',
                    }}>
                        <img
                            src={info.default}
                            alt="info"
                            style={{
                                position: 'relative',
                                top: '6px',
                                marginRight: '3px',
                                width: '24px',
                                height: '24px',
                            }} />A standard drink is like a 12 oz 5% beer, 5 oz glass of wine, or a 1.5 oz shot of hard liquor (80proof)</Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}
                    >
                        Over the last week, how many standard drinks did you have on a typical drinking day?
                    </Typography>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <IconButton
                            data-test-id="decrement"
                            onClick={this.handleDecrement}
                            sx={{
                                borderRadius: '50%',
                                backgroundColor: '#00A7D1',
                                color: 'white',
                                width: '32px',
                                height: '32px',
                                '&:hover': {
                                    backgroundColor: '#0096C7'
                                }
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <input
                            type="text"
                            data-test-id="changedrink"
                            value={drinkCount}
                            onChange={(event) => this.handleInputChange(event)}
                            style={{
                                height: '32px',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                width: '51px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                textAlign: 'center',
                                outline: 'none',

                            }}
                            onBlur={(e) => {
                                if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                }
                            }}
                            onFocus={(e) => {
                                if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                }
                            }}
                        />
                        <IconButton
                            data-test-id="increment"
                            onClick={this.handleIncrement}
                            sx={{
                                borderRadius: '50%',
                                backgroundColor: '#00A7D1',
                                color: 'white',
                                width: '32px',
                                height: '32px',
                                '&:hover': {
                                    backgroundColor: '#0096C7'
                                }
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </div>
                    {this.state.drinkCount == 0 && this.state.AlcohalButtonCalled && <p style={{
                        color: "#EB3E3E",
                        fontFamily: "Chromatica-Regular",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px", margin: 0, marginTop: '5px'
                    }}>Select value greater than 0</p>}
                    <Typography sx={{
                        fontFamily: 'Chromatica-Regular',
                        fontSize: '16px',
                        color: '#252525',
                        marginTop: '30px',
                        marginBottom: '20px'
                    }}>
                        What would you like to reduce that amount to this week?
                    </Typography>

                    <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                        <IconButton
                            onClick={this.handleDecrementSecond}
                            sx={{
                                color: 'white',
                                width: '32px',
                                borderRadius: '50%',
                                backgroundColor: '#00A7D1',
                                height: '32px',
                                '&:hover': {
                                    backgroundColor: '#0096C7'
                                }
                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                        <input
                            type="text"
                            data-test-id="handleinput"
                            value={this.state.drinkCountSecond}
                            onChange={(event) => this.handleInputChangeSecond(event)}
                            style={{
                                textAlign: 'center',
                                outline: 'none',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                width: '51px',
                                borderRadius: '4px',
                                height: '32px',
                                border: '1px solid #E3E3E3',

                            }}
                            onFocus={(event) => {
                                if (event.target.style) {
                                    event.target.style.border = '1px solid #E3E3E3';
                                }
                            }}
                            onBlur={(event) => {
                                if (event.target.style) {
                                    event.target.style.border = '1px solid #E3E3E3';
                                }
                            }}

                        />
                        <IconButton
                            onClick={this.handleIncrementSecond}
                            sx={{
                                height: '32px',
                                backgroundColor: '#00A7D1',
                                borderRadius: '50%',
                                '&:hover': {
                                    backgroundColor: '#0096C7'
                                },
                                color: 'white',
                                width: '32px',
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </div>
                    {this.state.AlcohalButtonCalled && drinkCount < this.state.drinkCountSecond &&
                        <p
                            style={{
                                color: "#EB3E3E",
                                fontFamily: "Chromatica-Regular",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px",
                                margin: 0,
                                marginTop: '5px'
                            }}
                        >
                            Select value less than {drinkCount}
                        </p>
                    }
                </>)
            case 'Reduce how often':
                return (
                    <>
                        <Typography
                            sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}
                        >
                            Out of the past 7 days, how many days did you drink alcohol?
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <IconButton
                                onClick={this.handleDecrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>

                            <input
                                type="text"
                                data-test-id="drinkcountoften"
                                value={this.state.drinkCountOften}
                                onChange={(event) => this.handleInputChangeOften(event)}
                                style={{
                                    width: '51px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    color: '#252525',
                                    height: '32px',
                                    textAlign: 'center',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    outline: 'none',

                                }}
                                onFocus={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                onBlur={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                            />
                            <IconButton
                                onClick={this.handleIncrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        {this.state.drinkCountOften == 0 &&
                            this.state.AlcohalButtonCalled &&
                            <p style={{
                                color: "#EB3E3E", fontSize: "16px",
                                lineHeight: "24px", margin: 0, marginTop: '5px', fontWeight: 500, fontFamily: "Chromatica-Regular",
                            }}>
                                Select value greater than 0</p>}

                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Out of the NEXT 7 days, how many days do you want to drink alcohol?
                        </Typography>

                        <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                            <IconButton
                                data-test-id="secondoften1"
                                onClick={this.handleDecrementSecondOften}
                                sx={{
                                    color: 'white',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="text"
                                value={this.state.drinkCountSecondOften}
                                onChange={(event) => this.handleInputChangeSecondOften(event)}
                                style={{
                                    fontFamily: 'Chromatica-Regular',
                                    height: '32px',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    color: '#252525',
                                    width: '51px',
                                    outline: 'none',

                                }}
                                onBlur={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                onFocus={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                            />
                            <IconButton
                                data-test-id="secondoften"
                                onClick={this.handleIncrementSecondOften}
                                sx={{
                                    height: '32px',
                                    backgroundColor: '#00A7D1',
                                    borderRadius: '50%',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    },
                                    color: 'white',
                                    width: '32px',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        {this.state.AlcohalButtonCalled && this.state.drinkCountOften < this.state.drinkCountSecondOften &&
                            <p
                                style={{
                                    color: "#EB3E3E",
                                    fontFamily: "Chromatica-Regular",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    margin: 0,
                                    marginTop: '5px'
                                }}
                            >
                                Select value less than {this.state.drinkCountOften}
                            </p>
                        }
                    </>
                )
            case 'Safer/healthier':
                return (<>
                    <Typography sx={{
                        fontFamily: 'Chromatica-Regular',
                        fontSize: '16px',
                        color: '#252525',
                        marginTop: '30px',

                    }}>
                        Check out our list of safer use strategies <span data-test-id="alcoholValue" onClick={() => this.handleSaferValueAlcohol()} style={{ textDecoration: 'underline', fontFamily: 'Chromatica-Bold', cursor: 'pointer' }}>Here</span> to choose your goals for the next week.
                    </Typography>
                </>)
            case 'Other':
                return (<div>
                    <Typography sx={{
                        fontFamily: 'Chromatica-Regular',
                        fontSize: '16px',
                        color: '#252525',
                        marginTop: '30px',
                        marginBottom: '20px'
                    }}>
                        What do you want to see happen with your drinking?
                    </Typography>

                    <TextField
                        onChange={(event) => this.handleChangeOther(event)}
                        value={this.state.OthersResponse}
                        placeholder="Type here..."
                        multiline
                        InputProps={{
                            disableUnderline: false,
                            sx: {
                                "&:after": {
                                    borderBottom: "1px solid #E3E3E3",
                                },
                                ".MuiInputBase-input": {
                                    paddingBottom: "5px",
                                },
                                "&:before": {
                                    borderBottom: "1px solid #E3E3E3",
                                },
                                "&:hover:not(.Mui-disabled):before": {
                                    borderBottom: "1px solid #E3E3E3",
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                fontFamily: "Chromatica-Regular",
                                fontSize: "16px",
                            },
                            sx: {
                                "&::placeholder": {
                                    fontSize: "16px",
                                    color: "#939393",
                                    fontFamily: "Chromatica-Bold",
                                    fontWeight: 700,
                                },
                            },
                        }}
                        sx={{
                            fontWeight: 700,
                            lineHeight: "24px",
                            mt: "25px",
                            color: "#939393",
                            maxWidth: "816px",
                            fontSize: "16px",
                            fontFamily: "Chromatica-Regular",
                            minWidth: '50px'
                        }}
                        rows={5}
                        variant="standard"
                        fullWidth
                    />
                    {


                        this.state.AlcohalButtonCalled && (
                            (this.state.OthersResponse?.length < 1 || isValidText) && (
                                <p style={{
                                    color: "#EB3E3E",
                                    fontFamily: "Chromatica-Regular",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    margin: 0,
                                    marginTop: '5px'
                                }}>
                                    {
                                        this.state.OthersResponse?.length < 1
                                            ? "Text field is required!"
                                            : "Please enter valid text!"
                                    }
                                </p>
                            )
                        )
                    }

                </div>)
            default:
                return null
        }
    }
    render() {

        const doesMatchFirstFive = this.doesMatchFirstFive()
        const shouldSelectEmptyDate = this.shouldSelectEmptyDate(doesMatchFirstFive)

        const valuesOptions = [{ value: 'Yes', label: `Yes` }, { value: 'No', label: `No` }]
    
        return (
            <Box sx={{ position: "relative" }}>
             
                
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <div style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'start',
                        flexWrap: 'wrap',
                    }}>
                        {this.state.options?.map((option: any) => (
                            <Box
                                key={option.value}
                                sx={{
                                    width:'227px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s, border-color 0.3s', 
                                    border: this.state.selectedValue === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                    backgroundColor: this.state.selectedValue === option.value ? '#EFF8FB' : 'transparent',
                                    borderRadius: '32px',
                                    padding: '8px 12px',
                                    whiteSpace: 'nowrap',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={this.state.selectedValue === option.value}     
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#00A7D1',
                                                },
                                                color: '#E3E3E3'
                                            }}
                                            onChange={(event)=>this.handleChange(event)}
                                            value={option.value}
                                        />
                                    }
                                    label={
                                        <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                    }
                                />
                            </Box>
                        ))}
                    </div>
                </FormControl>

                {this.getSelectedValueComponent(this.state.selectedValue)}

                {this.state.selectedValue === 'Stop completely' && (
                    <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. <br/>Are you sure you want to take a break from drinking at this point?
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            gap: '10px'
                        }}>
                            {valuesOptions?.map((option: any) => (
                                <Box
                                    key={option.value}
                                    sx={{
                                        display: 'inline-flex',
                                        border: this.state.selectedValueOption === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                        backgroundColor: this.state.selectedValueOption === option.value ? '#EFF8FB' : 'transparent',
                                        borderRadius: '32px',
                                        padding: '8px 12px',
                                        alignItems: 'center',
                                        transition: 'background-color 0.3s, border-color 0.3s',
                                        whiteSpace: 'nowrap',
                                        height: '40px',
                                        width:'100px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.selectedValueOption === option.value}
                                                onChange={this.handleChangeOption}
                                                value={option.value}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#00A7D1',
                                                    },
                                                    color: '#E3E3E3'
                                                }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                        }
                                    />
                                </Box>
                            ))}
                        </div>
                        {this.state.selectedValueOption == "" && this.state.AlcohalButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select Yes or No. This field is required!</p>}
          {this.state.selectedValueOption==='No'  && (
  <p
    style={{
      color: "#EB3E3E",
      margin: "0",
      fontSize: "16px",
      fontFamily: "Chromatica-Regular",
      marginTop: "20px",
      fontWeight: 500,
      marginBottom: "20px",
      lineHeight: "24px",
    }}
  >
   Please select a new goal or click on "Yes" to continue.
  </p>
)}

          {this.state.selectedValueOption!=='No'?( <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Way to commit to taking a break from drinking! What day this week do you want to start?
                        </Typography>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                                gap: '10px'
                            }}>
                                {this.state.optionsDate?.map((option: any) => {
                                        const formatedDate = this.state.formattedDateInter && moment(this.state.formattedDateInter).format('YYYY-MM-DD')
                                        const isSelected = formatedDate === option.date || (shouldSelectEmptyDate && option.date === '');
                                        return (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
        
                                                    border: isSelected ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                                   backgroundColor: isSelected ? '#EFF8FB' : 'transparent',
                                                    borderRadius: '32px',
                                                    padding: '8px 12px',
                                                    transition: 'background-color 0.3s, border-color 0.3s',
                                                    whiteSpace: 'wrap',
                                                }}
                                                key={option.value}
                                            >
                                                <FormControlLabel
                                                    label={<span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#00A7D1',
                                                                },
                                                                color: '#E3E3E3',
                                                            }}
                                                            onChange={() => this.handleChangeDates(option)}
                                                            value={option.value}
                                                            checked={isSelected}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        );
                                    })}
                            </div>
                        </FormControl>
                        {(this.state.valueOther === 'other' ||  shouldSelectEmptyDate) && (
                                <div style={{ marginTop: '20px', alignItems: 'center', display: 'flex', maxWidth: '286px',minWidth:'50px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                    data-test-id="popip"
                                        onClick={() => this.openPopUp(1)}
                                        type="text"
                                        placeholder="Choose Day"
                                        value={shouldSelectEmptyDate ? this.state.formattedDateInter : ''}
                                        style={{
                                            outline: 'none',
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                        readOnly
                                    />
                                    <img
                                      data-test-id="popimg"
                                        onClick={() => this.openPopUp(1)}
                                        src={calendar.default}
                                        alt="Calendar"
                                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    />
                                </div>
                            )} </>):''}
                             <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        padding: '25px 10px 15px 10px', width: '400px', display: 'flex', flexDirection: 'column',
                                        borderRadius: '16px',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>

                                        <DatePickerAlcohol onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined}  value={this.state.formattedDateInter} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '43px',
                                                border: '1px solid #00A7D1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px',
                                            }}

                                                onClick={() => this.closePopUp()}
                                                data-test-id="closebtn"
                                            > Back
                                            </Button>
                                            <Button style={{
                                                background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmCal()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                        {this.state.dateErrorMessage && (
                          <p
                            style={{
                              color: "#EB3E3E",
                              fontFamily: "Chromatica-Regular",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "24px",
                              margin: "8px 0 0",
                              paddingLeft: "16px",
                            }}
                          >
                            {this.state.dateErrorMessage}
                          </p>
                        )}
                                    </Card>
                                )}
                            </Backdrop>
                            

{this.state.formattedDateInter?.length < 3 && this.state.AlcohalButtonCalled  && (
  <p
    style={{
      color: "#EB3E3E",
      margin: "0",
      fontSize: "16px",
      fontFamily: "Chromatica-Regular",
      marginTop: "5px",
      fontWeight: 500,
      lineHeight: "24px",
      display: this.state.hideDateError ? "none": "",
    }}
  >
    Date is required!
  </p>
)}
                    </>
                )}   
                {
( this.state.selectedValueOption !== 'No') &&
<Button
data-test-id="addgoalsalcohol"
onClick={()=>{this.SaveAlcohalData()}}
    variant="contained"
    sx={{
     padding: "10px",
     maxWidth: '348px',
     width: '100%',
     minWidth:'50px',
     height: '56px',
     borderRadius: "16px",
     boxShadow: 'none',
     marginTop: '25px',
     marginBottom: '30px',
     background:
         "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
     cursor: 'pointer',
     backgroundColor: '#EFF8FB',
     fontSize: "18px",
     lineHeight: "20px",
     fontWeight: 400,
     fontFamily: 'Chromatica-Bold',
   textTransform: "none"}}>
    <span style={{ marginRight: '5px' }}>Add Goal</span>
    <img src={arrowright.default} />
</Button>  
                }
              
            </Box>
        )
    }
}
