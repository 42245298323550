import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { v4 as uuidv4 } from "uuid";
export const baseURL = require("../../../framework/src/config.js");
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  termsAndCondition:boolean;
  otherCategory:any,
  dateRange:string,
  valueThisWeek:any,
  valueLastWeek: any,
  activeStep:number;
  value:number;
  values:number;
  selectValues:number;
  selectedCategory: string;
  isPopupOpen:any;
  AchievedGoal: boolean;
  openCongrates:boolean;
  showSectionyesandno:boolean;
  openWell:boolean;
  getLastweeksaferuserData:any;
  getthisweekachiveData:any
  cardIdgoals:any;
  showAchievedStrategylast:any
  userName:any,
  openWellThisWeek:any,
  categories: any;
  goalText: string;
  deleteModal: boolean;
  deleteData:{
    cardId: number | undefined,
    value: any,
    index:any
  }


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalsInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubstance:string="";
  getSubstanceLastWeek:string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  othergoalsThisweekApiCallId : string = "";
  othergoalsLastweekApiCallId: string = "";
  acheivegoalsAPIDataCallID :  string = "";
  acheiveSubstancegoalsAPIDataCallID :  string = "";
  deleteOtherGoalCallId:string =""
  deleteSubstanceGoalCallId: string =""
  getCategoriesListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      termsAndCondition:true,
      dateRange:'',
      otherCategory:[],
      valueThisWeek:[],
      valueLastWeek: [],
      activeStep:0,
      value:0,
      values:0,
      selectValues:0,
      selectedCategory: "Alcohol",
      openCongrates:false,
      showSectionyesandno:false,
      openWell:false,
      openWellThisWeek:{},
      isPopupOpen:null,
      AchievedGoal: false,
      getLastweeksaferuserData:[],
      getthisweekachiveData:[],
      cardIdgoals:null,
      showAchievedStrategylast:null,
      userName:'',
      categories: [],
      goalText: "",
      deleteModal: false,
      deleteData:{
        cardId:undefined,
        value:undefined,
        index:undefined
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (apiRequestCallId && apiRequestCallId === this.othergoalsThisweekApiCallId) {
        this.thisWeekResponse(responseJson);
      }else if (apiRequestCallId && apiRequestCallId === this.othergoalsLastweekApiCallId) {
        this.lastweekresponse(responseJson);
    }
       else if (apiRequestCallId && apiRequestCallId === this.acheivegoalsAPIDataCallID) {
        this.otherPopachieve(responseJson);
      } else if (apiRequestCallId && apiRequestCallId === this.acheiveSubstancegoalsAPIDataCallID) {
        this.subStancePopachieve(responseJson);
      } else if (apiRequestCallId && apiRequestCallId === this.getSubstanceLastWeek) {
        this.noResJson(responseJson)
        this.handleLastWeekResponse(responseJson);
      } else if(apiRequestCallId && apiRequestCallId === this.deleteOtherGoalCallId){
        /* istanbul ignore next */
        this.resetOnDelete()  
        if(this.state.value === 0) {
          if(this.state.values === 0) {
            this.getSubstanceGoalsThisWeek(this.state.selectedCategory);
          } else {
            this.othergoalsThisweekAPIdData();
          }
        } else {
          if(this.state.values === 0) {
            this.getSubstanceGoalsLastWeek(this.state.selectedCategory);
          } else {
            this.othergoalsLastweekAPIdData();
          }
        }
      } else if(apiRequestCallId && apiRequestCallId === this.deleteSubstanceGoalCallId){
        this.handleDeleteResponse()
      } else if(apiRequestCallId && apiRequestCallId === this.getCategoriesListApiCallId) {
        this.handleCategories(responseJson);
      } 
  }
    if (isResponseMessage) {
      this.handleThisWeek(responseData, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  noResJson = (responseJson: any) => {
    if(responseJson?.length==0){
      this.setState({ valueThisWeek:[],otherCategory:[], isLoading: false });
    }
  };
  handleDeleteResponse = () =>{
    this.resetOnDelete()  
    this.setState({ isPopupOpen: null });
    if(this.state.value === 0) {
      if(this.state.values === 0) {
        this.getCategoriesList();
      } else {
        this.othergoalsThisweekAPIdData();
      }
    } else {
      if(this.state.values === 0) {
        this.getCategoriesList();
      } else {
        this.othergoalsLastweekAPIdData();
      }
    }
  }

  handleThisWeek = (responseData: any, responseJson: any) => {
    if (this.getSubstance === responseData && !responseJson.errors) {
      this.handleThisWeekResponse(responseJson)
      }
  };

  handleThisWeekResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if(responseJson?.length==0){
      this.setState({ valueThisWeek:[],otherCategory:[] });
    }
      let answer: any[] = [];
      let dateRange: any[] = []; 
      let descriptionAdded: any[] = [];
      let cardIds: any[] = [];
        responseJson?.forEach((item: { id: any; responses: { [x: string]: any; }; description: any; date_range: any; category_name: any }) => {
          if (item?.category_name === 'Others') {
            this.setState(prevState => ({
              otherCategory: [...prevState.otherCategory, item?.responses?.answer]
            }));
          } else {
            dateRange.push(item?.date_range);
            descriptionAdded.push(item?.description);
            cardIds.push(item?.id);
      
            if (item?.responses) {
              const responseKey = Object.keys(item?.responses)[0];
              answer.push(responseKey);
            }
          }
        });
        this.setState({
          valueThisWeek: {
            cardIds,
            responses: answer,
            dateRange: dateRange, 
            descriptionAdded: descriptionAdded,
            rootItem:responseJson 
          },
        });
  };

  handleLastWeekResponse = (responseJson: any) => {
      let cardIds: any[] = [];
      let answer: any[] = [];
      let dateRange: any[] = []; 
      let descriptionAdded: any[] = [];
      let status: any[] = [];
        responseJson?.forEach((item: { id: any; responses: { [x: string]: any; }; description: any; date_range: any; category_name: any, status: any }) => {
          if (item?.category_name === 'Others') {
            this.setState(prevState => ({
              otherCategory: [...prevState.otherCategory, item?.responses?.answer]
            }));
          } else {
            dateRange.push(item?.date_range);
            descriptionAdded.push(item?.description);
            status.push(item?.status);
            cardIds.push(item?.id);

            if (item?.responses) {
              const responseKey = Object.keys(item?.responses)[0];
              answer.push(responseKey);
            }
          }
        });
        this.setState({
          valueLastWeek: {
            cardIds,
            responses: answer,
            dateRange: dateRange, 
            descriptionAdded: descriptionAdded,
            status: status,
            rootItem:responseJson
          },
          isLoading: false,
        });
  };

  handleCategories = (responseJson: any) => {
    this.setState({ categories: responseJson.categories_list }, () => {
      const categoryArray = ["Alcohol", "Cannabis", "tobaccoOrNicotine", "upppers", "downers"];
      const matchedCategory = categoryArray.find(category => this.state.categories.includes(category));
      const isExist = this.state.categories.includes(this.state.selectedCategory);
      if(this.state.value === 0) {
        this.getSubstanceGoalsThisWeek(isExist ? this.state.selectedCategory : matchedCategory);
      } else {
        this.getSubstanceGoalsLastWeek(isExist ? this.state.selectedCategory : matchedCategory);
      }
    });
  };

  async componentDidMount() {
    // Customizable Area Start
    const selectedCategory = await getStorageData("selectedCategory") || "";
    const value = await getStorageData("weekValue") || 0;
    const values = await getStorageData("goalValue") || 0;
    removeStorageData("selectedCardId");
    removeStorageData("susSelectedCategory");
    removeStorageData("susWeekValue");
    removeStorageData("newData");
    removeStorageData("stillWorking");
    // remove firstid
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    this.setState({
      userName: localStorage.getItem("username"),
      selectedCategory,
      value: Number(value),
      values: Number(values),
    });
    if(Number(values) === 0) {
      this.getCategoriesList();
    } else {
      if(Number(value) === 0) {
        this.othergoalsThisweekAPIdData();
      } else {
        this.othergoalsLastweekAPIdData();
      }
    }
    removeStorageData("selectedCardId");
    // Customizable Area End
  }
  othergoalsThisweekAPIdData(): boolean {
    // Customizable Area Start
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.othergoalsThisweekApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.thisWeekEndpoint}?timezone=${timeZone}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  othergoalsLastweekAPIdData(): boolean {
    // Customizable Area Start
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.othergoalsLastweekApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.lastWeekEndpoint}?timezone=${timeZone}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  acheivegoalsAPIData(cardIdlast: any): boolean {
    // Customizable Area Start
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acheivegoalsAPIDataCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.otacheiveEndpoint}/goal_achieved?id=${cardIdlast}` 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  substanceAcheivegoalsAPIData(cardIdlast: any): boolean {
    // Customizable Area Start
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acheiveSubstancegoalsAPIDataCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.substanceAcheiveEndpoint}/goal_achieved?id=${cardIdlast}` 
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  getSelectedTab = (selectedTab: number) => {
    switch(selectedTab) {
      case 0:
        return "Alcohol";
      case 1:
        return "Cannabis";
      case 2:
        return "Tobacco or Nicotine";
      case 3:
        return "Uppers";
      case 4:
        return "Downers";
      default:
        return "Others";
    }
  };

  handleEditSubstanceGoal = (value: number, index: number) => {
    const selectedCardId = (value === 0 ? this.state.valueThisWeek : this.state.valueLastWeek)?.cardIds?.[index];
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    localStorage.removeItem("valuesSequence");
    localStorage.removeItem("currentValue");
    localStorage.removeItem("selectedTab");
    removeStorageData("selectedSubstance");
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    removeStorageData("stillWorking");
    setStorageData("selectedCardId", selectedCardId);
  };

  handleEditOtherGoal = (cardId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "OtherGoals");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    setStorageData("selectedCardId", cardId);
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    removeStorageData("stillWorking");
  };

  goToBackDashboard = () =>{
    this.props.navigation.navigate("DashboardInformation")
  }


  hamburgerMenunext(){
    localStorage.setItem("pageNavigate","GoalsInformation")
    this.props.navigation.navigate("HamburgerMenu")
  }
  showAnswer(card:any){
    if(card==='stop_completely'||card==='stopCompletely'||card==='downersStopCompletely'||card==='UppersStopCompletely'){
      return 'Stop completely'
    }
    else if(card==='reduce_how_much'||card==="downersReduceHowMuch"||card==='UppersReduceHowMuch' || card==='reduceHowMuch'){
      return 'Reduce how much'
    }
    else if(card==='reduce_how_often'||card==="downersReduceHowOften"||card==='reduceHowOften'||card==='UppersReduceHowOften'){
      return 'Reduce how often'
    }
    else if(card==='others'||card==='Others'){
      return 'Others'
    }
    else if(card==='safer_healthier'|| card==='Safer/healthier'){
      return 'Safer/healthier'
    }
  }
  handleChange = (event:any,newValue: any) => {
    this.setState({ isLoading: true, valueThisWeek: [], valueLastWeek: [], getthisweekachiveData: [], getLastweeksaferuserData: []});
    setStorageData("weekValue", newValue.toString());
    if(newValue === 0) {
      if(this.state.values === 0) {
        this.getCategoriesList();
      } else {
        this.othergoalsThisweekAPIdData();
      }
    } else {
      if(this.state.values === 0) {
        this.getCategoriesList();
      } else {
        this.othergoalsLastweekAPIdData();
      }
    }
    this.setState({ value: newValue });
  };
  handleChangesecond = (event:any ,newValues: any) => {
    this.setState({ isLoading: true, valueThisWeek: [], valueLastWeek: [], getthisweekachiveData: [], getLastweeksaferuserData: []});
    setStorageData("goalValue", newValues.toString());
    setStorageData("selectedCategory", "Alcohol");
    if(newValues === 0) {
      this.getCategoriesList();
    } else {
      if(this.state.value === 0) {
        this.othergoalsThisweekAPIdData();
      } else {
        this.othergoalsLastweekAPIdData();
      }
    }
    this.setState({ values: newValues });
  };
  handleChangethird = (event:any ,newValues: any) => {
    const selectedCategory = ["Alcohol", "Cannabis", "tobaccoOrNicotine", "uppers", "downers"][newValues];
    setStorageData("selectedCategory", selectedCategory);
    this.setState({ selectValues: newValues, selectedCategory });
    this.setState({
      isPopupOpen:false
    })
  }
  handleButtonAchievedGoal = () => {
    this.setState((prevState) => ({
      AchievedGoal: !prevState.AchievedGoal, 
    }));
  };

  handleOpen = (cardIdlast:any, goalText: string, isOtherGoal: boolean) => {
    if(isOtherGoal) {
      this.acheivegoalsAPIData(cardIdlast);
    } else {
      this.substanceAcheivegoalsAPIData(cardIdlast);
    }
    this.setState({ showAchievedStrategylast: cardIdlast ,  openCongrates: true, goalText });
  }

  setGoalState = (valueCurrent: any) => {
    if(valueCurrent=='Alcohol') {
      this.setState({selectValues:0});
    }
    if(valueCurrent=='Cannabis') {
      this.setState({selectValues:1});
    }
    if(valueCurrent=='tobaccoOrNicotine'){
      this.setState({selectValues:2})
    }
    else if(valueCurrent=='uppers'){
      this.setState({selectValues:3})
    }
    else if(valueCurrent=='downers'){
      this.setState({selectValues:4})
    }
    else if(valueCurrent=='Others'){
      this.setState({selectValues:5})
    }
  };

  getSubstanceGoals = (value: number, valueCurrent: string) => {
    if(!value) {
      this.getSubstanceGoalsThisWeek(valueCurrent);
    } else {
      this.getSubstanceGoalsLastWeek(valueCurrent);
    }
  };

  getCategoriesList = () => {
    this.setState({ isLoading: true });
    let token = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token
    };
    const categoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesListApiCallId = categoryMessage.messageId;
    categoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.goalCategoriesEndPoint
    );
    categoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    categoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(categoryMessage.id, categoryMessage);
  };

  getSubstanceGoalsThisWeek = (valueCurrent:any) => {
    this.setState({ isLoading: true, valueThisWeek: [] });
    this.setGoalState(valueCurrent);
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubstance = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubstanceGoalsThisWeek}${valueCurrent}&timezone=${timeZone}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getSubstanceGoalsLastWeek = (valueCurrent:any) => {
    this.setState({ isLoading: true, valueLastWeek: [] });
    this.setGoalState(valueCurrent);
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubstanceLastWeek = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SubstanceGoalsLastWeek}${valueCurrent}&timezone=${timeZone}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  handleClose = () => {
    this.setState({ openCongrates: false });
    this.getSubstanceGoalsLastWeek(this.state.selectedCategory);
  };
  goToGoals = ()=>{
      /* istanbul ignore next */
    localStorage.setItem("NewUseFound","No")
    removeStorageData("newData");
    removeStorageData("selectedCardId");
    removeStorageData("selectedSubstance");
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    localStorage.removeItem("valuesSequence");
    localStorage.removeItem("currentValue");
    removeStorageData("selectedSubstance");
    this.props.navigation.navigate("Goals")
  }
  handleButtonClick = async () => {
    this.setState((prevState) => ({
      showSectionyesandno: !prevState.showSectionyesandno, 
    }));
    setStorageData("stillWorking", true);
    const routePath = this.state.values === 0 ? "CustomForm" : "OtherGoals";
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), routePath);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  handleClosewell = () => {
    this.setState({ openWell: false });
    removeStorageData("selectedCardId");
  };
  handleClosewell2 = (index: any) => {
    this.setState((prevState: any) => ({
      openWellThisWeek: {
        ...prevState.openWellThisWeek,  
        [index]: false 
      }
    }));
  };
  handleIconClick = (id:any) => {
    this.setState((prevState) => ({
      isPopupOpen: prevState.isPopupOpen === id ? null : id, 
    }), () => {
      document.addEventListener('mousedown', this.handleClickOutsidePopup);
    });
  };

  handleClickOutsidePopup = (event: any) => {
    if (!event.target.closest('.popup-container')) {
        this.setState({ isPopupOpen: false }, () => {
            document.removeEventListener('mousedown', this.handleClickOutsidePopup);
        });
    }
  };

  handleOpenwell2 = (index: any) => {
    this.setState((prevState: any) => ({
      openWellThisWeek: {
        ...prevState.openWellThisWeek,  
        [index]: !prevState.openWellThisWeek[index] 
      }
    }));
  };
  
  handleOpenwell = (selectedCardId: number) => {
    this.setState({ openWell: true });
    setStorageData("selectedCardId", selectedCardId);
  };

  thisWeekResponse=(responseJson: any)=>{
    this.setState({ isLoading: false });
    if (responseJson) {
    if (responseJson.other_life_goals) {
      this.setState({ getthisweekachiveData: responseJson.other_life_goals});
  } else {
      this.setState({ getthisweekachiveData: [] });
  }
}
   }
  lastweekresponse=(responseJson: any)=>{
    this.setState({ isLoading: false });
    if (responseJson) {
    if (responseJson.other_life_goals) {
      this.setState({ getthisweekachiveData: responseJson.other_life_goals});
    } else {
      this.setState({ getthisweekachiveData: [] });
    }
  }
  }
   otherPopachieve=(responseJson: any)=>{
    if (responseJson) {
      if(responseJson.message && responseJson.message.includes("marked achieved.")) {
        this.othergoalsLastweekAPIdData();
      }
    if (responseJson.other_life_goals) {
      this.setState({ getLastweeksaferuserData: responseJson.other_life_goals });
  } else {
      this.setState({ getLastweeksaferuserData: []});
  }
}   
   }

  subStancePopachieve= async (responseJson: any)=>{
    const selectedCategory = await getStorageData("selectedCategory");
    if (responseJson) {
      if(responseJson.message && responseJson.message.includes("marked achieved.")) {
        this.getSubstanceGoalsLastWeek(this.state.selectedCategory || selectedCategory);
      }
    if (responseJson.other_life_goals) {
      this.setState({ getLastweeksaferuserData: responseJson.other_life_goals });
    } else {
        this.setState({ getLastweeksaferuserData: []});
    }
  }
  }
  handleDeleteOtherGoal(cardId: number){
    let data = localStorage.getItem("token") || "{}"
    const header = {
      token: data
    };

    const deleteOtherGoal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteOtherGoalCallId = deleteOtherGoal.messageId;

    deleteOtherGoal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteOtherGoalsApiEndPoint}/${cardId}`
    );

    deleteOtherGoal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteOtherGoal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeletesaferUserAPiMethod
    );


    runEngine.sendMessage(deleteOtherGoal.id, deleteOtherGoal);

    return true;
  }

  handleDeleteSubstanceGoal(value: number, index: number){
    const selectedCardId = (value === 0 ? this.state.valueThisWeek : this.state.valueLastWeek)?.cardIds?.[index];

    let data = localStorage.getItem("token") || "{}"
    const header = {
      token: data
    };

    const deleteSubstanceGoal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubstanceGoalCallId = deleteSubstanceGoal.messageId;

    deleteSubstanceGoal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSubstanceGoalsApiEndPoint}/${selectedCardId}`
    );

    deleteSubstanceGoal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteSubstanceGoal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeletesaferUserAPiMethod
    );


    runEngine.sendMessage(deleteSubstanceGoal.id, deleteSubstanceGoal);

    return true;
  }
  closeDeleteModal = () =>{
    this.setState({deleteModal: false})
  }
  openDelteModal = (data:any) =>{
    this.setState({deleteModal: true, deleteData:data})
    
  }
  onGoalDelete= () =>{
    const {cardId,value,index} = this.state.deleteData
    if(cardId){
      this.handleDeleteOtherGoal(cardId)
    } else {
      this.handleDeleteSubstanceGoal(value,index)
    }
  }
  resetOnDelete = () => {
    this.setState({ isPopupOpen: null,deleteModal: false,deleteData:{index: undefined, cardId:undefined,value:undefined} });
  }
  getUTCDate = (targetDate: string, reminderTime: string) => {
    const timePart = reminderTime?.split("T")[1];
    const utc = `${targetDate}T${timePart}`;
    return utc

  }
  // Customizable Area End
}