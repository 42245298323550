import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPassword: boolean;
  username: string;
  termsAndCondition: boolean;
  showAndHideTermsAndCondition: boolean;
  loginStatus: boolean;
  message: string;
  description: string;
  buttonClick: boolean;
  UserEnterPassword: string;
  UserEnterUserName: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryHandleValue: string = "";
  apiEmailLoginCallId: string = "";
  apiEmailLoginwebCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPassword: false,
      username: "",
      termsAndCondition: false,
      showAndHideTermsAndCondition: false,
      loginStatus: false,
      message: "",
      description: "",
      buttonClick: false,
      UserEnterPassword: "",
      UserEnterUserName: "",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.handleLoginApi = this.handleLoginApi.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    localStorage.setItem("token", "");
    window.addEventListener("keydown", this.handleKeyDown);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.getCategoryHandleValue) {
          if (responseJson?.new_user) {
            this.props.navigation.navigate("Categoriessubcategories");
            localStorage.setItem("NewUseFound", "Yes");
            localStorage.setItem("dashboardUser", "true");
            window.location.reload();
          } else {
            this.props.navigation.navigate("DashboardInformation");
            localStorage.setItem("NewUseFound", "No");
            localStorage.setItem("dashboardUser", "false");
            window.location.reload();
          }
        }
        if (apiRequestCallId === this.apiEmailLoginwebCallId) {
          if (
            responseJson &&
            responseJson.token &&
            this.state.termsAndCondition
          ) {
            localStorage.setItem("token", responseJson?.token);
            localStorage.setItem("firebaseUserId", responseJson?.id);
            localStorage.setItem("useEffect", "no");
            localStorage.setItem("username", responseJson?.user_name);
            this.setState({
              loginStatus: true,
              message: "Login Success!",
            });
            this.CategoriesGet();
          } else if (responseJson && responseJson.token) {
            this.setState({
              loginStatus: false,
              message: "Login Failed! ",
            });
          } else {
            this.setState({
              loginStatus: false,
              message: "Login Failed!",
            });
          }
        } else {
          this.setState({
            description:
              responseJson &&
              responseJson?.data &&
              responseJson?.data[0]?.description,
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.terms_and_condition_api_call
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleClickPasswordShow = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required!"),

    password: Yup.string().required("Password is required!"),
  });
  handleOpen = () => {
    this.setState({
      showAndHideTermsAndCondition: true,
    });
  };
  handleClose = () => {
    this.setState({
      showAndHideTermsAndCondition: false,
    });
  };
  handleContactUsOpen = () => {
    localStorage.setItem("ContactUs", "EmailAccountLoginBlock");
    this.props.navigation.navigate("ContactUs");
  };

  CategoriesGet = () => {
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryHandleValue = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryShowPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleLoginApi() {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      user_name: this.state.username,
      password: this.state.password,
      terms_and_conditions: this.state.termsAndCondition,
    };

    const loginapirequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginwebCallId = loginapirequestMessage.messageId;
    loginapirequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoints
    );

    loginapirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    loginapirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    loginapirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(loginapirequestMessage.id, loginapirequestMessage);

    return true;
  }

  handleCloseToast = () => {
    this.setState({ loginStatus: false });
  };

  handleKeyDown(event: { key: string }) {
    const modalContent = document.getElementById("modal-content");
    if (event.key === "ArrowDown" && modalContent) {
      modalContent.scrollBy({ top: 100, behavior: "smooth" });
    } else if (event.key === "ArrowUp" && modalContent) {
      modalContent.scrollBy({ top: -100, behavior: "smooth" });
    }
  }

  // Customizable Area End
}
