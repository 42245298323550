import React from 'react';
import DateOtherGoals from '../../landingpage/src/DateOtherGoals.web';
import {
    Grid,
    Box,
    Typography,
    Radio, FormControlLabel, 
    FormControl,
    Button,
    Checkbox,
    Backdrop, CardActions, Card, CardContent,
    CircularProgress,
    TextField,
    Dialog,
    DialogTitle
} from "@mui/material";
import Confetti from 'react-confetti';
import OtherGoalsController, {
    Props,
} from './OtherGoalsController.web';
import { 
    arrow,
    menuAdded,  
    three,
    bottomBannerImg,
    time, 
    calendar, 
    arrowright,
    secondImage, 
    award_a6 
} from './assets';
import TimeOtherGoals from '../../landingpage/src/TimeOtherGoals.web';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const configJSON = require("./config.js");


export default class OtherGoals extends OtherGoalsController {
    constructor(props: Props) {
        super(props);

    }

    otherNavRender = () => {
        return (
            <Grid item
            style={{
                background: '#f0f0f0',
            }}>
            <Grid
                item
                xs={12}
                sx={otherStyles.otherGridStylegoals}
            >
                <Box
                    sx={{...otherStyles.otherdivStylegoals, "@media (max-width: 450px)": {
                gap: "10px",
              }}}
                >
                    <Box
                        sx={otherStyles.othergapMgoals}
                    >
                        <Box
                            component="span"
                            sx={otherStyles.otherparentWellgoals}
                        >
                            Well
                        </Box>
                        Spring
                    </Box>
                    <Typography
                        sx={{...otherStyles.typoText, "@media (max-width: 590px)": {
                  width: "40%",
                  wordBreak: "break-word",
                }}}
                    >
                        Hey, {this.state.userName}
                    </Typography>
                </Box>
                <Box
                    data-test-id="backBtn"
                    component="img"
                    src={menuAdded.default}
                    onClick={() => this.hamburgerPage()}
                    alt="menuIcon"
                    sx={otherStyles.hamburgerIcon}
                />
            </Grid>
        </Grid>
        )
    }

    renderTabs = () => {
        const { gridItems, checkedItems } = this.state;
        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div style={{ overflow: 'hidden', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                    <Grid sx={{ display: 'flex', flexDirection: { md: 'row', sm:'row', xs: 'column' }, }}>
                        {/* istanbul ignore next */}
                        {gridItems?.map((item: any) => {
                            /* istanbul ignore next */
                            return (
                                <Grid
                                    data-test-id="gridValue"
                                    /* istanbul ignore next */
                                    onClick={() => this.handleCheckboxChange(item.id, item.label)}
                                    key={item.id}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '100%',
                                        height: { md: '204px', sm: '204px', xs: '125px'},
                                        position: 'relative',
                                        marginRight: '10px',
                                        marginLeft: { md: '10px', sm: '10px', xs:'0' },
                                        marginBottom: { md: '0', sm: '0', xs:'10px' },
                                        border: this.getItemBorder(item.id),
                                        borderRadius: '10px',
                                        background: this.getItemColor(item.id)
                                    }}
                                >
                                    <Checkbox
                                        checked={checkedItems[item.id] || false}
                                        sx={{
                                            position: 'absolute',
                                            borderRadius: '6px',
                                            top: 0,
                                            right: 0,
                                            color: checkedItems[item.id] ? '#00A7D1' : '#E3E3E3',
                                            '&.Mui-checked': {
                                                color: '#00A7D1',
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: { md: '35%', sm: '35%', xs: '40%' },
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <img
                                            src={item.image}
                                            alt={item.label}
                                            style={{ width: '80px', height: '80px' }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: { md: '72px', sm: '72px', xs: '13px' },
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            width: '300px',
                                        }}
                                    >
                                        {/* istanbul ignore next */}
                                        {item?.label}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>
        )
    }

    renderErrorMsg = () => {
        return (
            <>
                <Typography sx={{
                    fontFamily: 'Chromatica-Regular',
                    fontSize: '16px',
                    color: '#252525',
                    marginTop: '30px',
                    marginBottom: '20px'
                }}>
                    {this.state.valueSaved=="Professional"?
                    `What is one thing you can do for your ${this.state.valueSaved.toString().toLowerCase()} life this week?`
                    :
                    `What is one thing you can do for your ${this.state.valueSaved.toString().toLowerCase()} this week?`
                    }
                    </Typography>
                <TextField
                    value={this.state.setValueInput}
                    placeholder="Type here..."
                    /* istanbul ignore next */
                    onChange={(event) => this.handleChangeInput(event)}
                    multiline
                    InputProps={{
                        disableUnderline: false,
                        sx: {
                        "&:after": {
                            borderBottom: "1px solid #E3E3E3",
                        },
                        "&:before": {
                            borderBottom: "1px solid #E3E3E3",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "1px solid #E3E3E3",
                        },
                        },
                    }}
                    inputProps={{
                        style: {
                            fontSize: "16px",
                            fontFamily: "Chromatica-Regular",
                        },
                        sx: {
                        "&::placeholder": {
                            fontSize: "16px",
                            color: "#939393",
                            fontWeight: 700,
                            fontFamily: "Chromatica-Regular",
                        },
                        },
                    }}
                    sx={{
                        width: "100%",
                        maxWidth: '894px',
                        fontFamily: 'Chromatica-Regular',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#939393",
                    }}
                    rows={2}
                    variant="standard"
                    fullWidth
                />
                {/* istanbul ignore next */}
                {this.state.checkAllValues && this.state.setValueInput == '' &&
                    <p style={{
                        color: "#EB3E3E",
                        fontFamily: "Chromatica-Regular",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px", marginTop: '5px'
                    }}>This field is required!</p>}
            </>
        )
    }

    renderBackDrop = () => {
        return (
            <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                {this.state.openCalender && (

                    <Card sx={
                        {
                            padding: { xs: '15px 5px', sm: '25px 10px' }, display: { xs: 'block', sm: 'block', md: 'flex' }, flexDirection: 'column',
                            borderRadius: '16px', gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center', maxWidth: { xs: '90%', sm: '80%', md: '60%' }, margin: 'auto'
                        }}
                    >

                        <DateOtherGoals onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined} value={this.state.formattedDateInter} onClose={undefined} />
                        <CardActions sx={{ display: 'flex', gap: '26px', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'center', }}>
                            <Button style={{
                                background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                textAlign: 'center', textTransform: 'none', width: '90px', height: '43px',
                                border: '1px solid #00A7D1',
                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                borderRadius: '8px',
                            }}
                                /* istanbul ignore next */
                                onClick={() => this.closePopUp()}
                                data-test-id="closebtn"
                            > Back
                            </Button>
                            <Button style={{
                                background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                borderRadius: '8px',
                            }}
                                data-test-id="confirmBtns"
                                /* istanbul ignore next */
                                onClick={() => this.confirmCal()}
                            > Confirm
                            </Button>
                        </CardActions>
                        {this.state.dateErrorMessage && (
  <p
    style={{
      color: "#EB3E3E",
      fontWeight: 500,
      lineHeight: "24px",
      fontFamily: "Chromatica-Regular",
      paddingLeft: "16px",
      fontSize: "16px",
      margin: "8px 0 0",
    }}
  >
    {this.state.dateErrorMessage}
  </p>
)}
                    </Card>
                )}
            </Backdrop>
        )
    }

    renderForm = (shouldSelectEmptyDate: any) => {
        return (
            <FormControl component="fieldset" style={{ width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: '10px',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    }
                }}>
                    {this.state.options?.map((option: any) => {

const isSelected = this.state.formattedDateInter === option.date && this.state.fixIssue || (shouldSelectEmptyDate && this.state.valueOtherDay && option.date === '');

                        return (
                            <Box
                                key={option.value}
                                sx={{
                                    display: 'inline-flex',
                                    border: isSelected ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                    backgroundColor: isSelected ? '#EFF8FB' : 'transparent',
                                    borderRadius: '32px',
                                    padding: '8px 12px',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s, border-color 0.3s',
                                    whiteSpace: 'nowrap',
                                    width: 'fit-content'
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={isSelected}
                                            onChange={() => this.handleChange(option)}
                                            value={option.value}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#00A7D1',
                                                },
                                                color: '#E3E3E3'
                                            }}
                                        />
                                    }
                                    label={
                                        <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                    }
                                />
                            </Box>)
                    })}
                </Box>
            </FormControl>
        )
    }

    render() {
        const doesMatchFirstFive = this.state?.options
            .slice(0, 5)
            .some((opt: any) => opt.date === this.state?.formattedDateInter);
       const shouldSelectEmptyDate =!doesMatchFirstFive && this.state?.formattedDateInter!=null;

        return (
            <>
            <Backdrop open={this.state.isLoading} style={{ color: '#fff', zIndex: 1000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                ref={this.scrollRef}
                id="modal-others"
                item
                style={{
                    overflowX:'hidden',
                    overflowY:'scroll',
                    backgroundColor: "#F0F0F0",                 
                    height:'100%',
                    padding: "10px",
                }}
            >
                {this.otherNavRender()}
                <Grid
                    item
                    style={{
                        marginTop: '10px',
                        height:'auto',
                        backgroundColor: 'white',
                        marginBottom: '10px',
                        position: 'relative',
                        width: '100%',            
                        borderRadius: '15px',
                        minHeight:'100vh',
                    }}
                >
                    <Box onClick={() => this.goalsPage()} data-test-id="back-btn-goals" sx={{ paddingTop: '30px', cursor: 'pointer' }} >
                        <Box
                          sx={{ position: "absolute", paddingTop: '6px', cursor: "pointer", left:{sm: 50, xs:20 } }}
                            component="img"
                            alt="Back"            
                            src={arrow.default}
                        />
                        <Typography
                            sx={{                  
                                fontFamily: 'Chromatica-Bold',
                                cursor: "pointer",
                                marginLeft: '18px',
                                color:'#252525',
                                position: "absolute",
                                left:{sm: 50, xs:40 },
                            }}>Back</Typography></Box>

                    <Box 
                    sx={{ 
                    top: 19, 
                    position: "relative", 
                  padding: { xs: '10px 20px 19px 20px', sm: '20px 10px 19px 50px', md: '20px 10px 19px 50px', lg: '0 50px 19px 50px' }}}>
                        <Box
                           src={localStorage.getItem('NewUseFound') =='Yes'?three.default:secondImage.default}
                        
                            alt="Logo Top Left"
                            component="img"                
                            sx={{ width: { sm: '100px', md: '120px' }, height: { sm: '150px', md: '180px' }, objectFit: 'contain', }}
                        />
                        <Typography
                            sx={{    
                                fontSize: { xs: '24px', sm: '28px', md: '32px', lg: '36px', xl: '40px' },
                                position: "absolute",
                                top: 0,
                                left: 0,
                                marginTop: '112px',
                                fontFamily: 'Josefin Sans',
                                fontWeight: '900',
                                color: '#00A7D1',
                                padding: { xs: '10px 20px 0 20px', sm: '20px 10px 0 50px', md: '20px 10px 0 50px', lg: '0px 50px' }
                            }}
                        >
                           Other Life Goals</Typography>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: {
                                xs: "16px",
                                sm: "18px",
                                md: "20px",
                            },
                            color: '#252525',
                            marginBottom: '20px'
                        }}
                        >
                            We are here to meet you where you're at. So, thanks for showing up and choosing a step towards wellness today!
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Bold',
                            fontSize: {
                                xs: "16px",
                                sm: "18px",
                                md: "20px",
                            },
                            paddingBottom: '10px',
                            color: '#252525'
                        }}>What is one area you want to work on?</Typography>
                            {this.renderTabs()}     
                        {this.state.checkAllValues  &&
                            Object.keys(this.state.checkedItems).length === 0 && <p style={{
                                color: "#EB3E3E",
                                fontFamily: "Chromatica-Regular",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px", marginLeft: '8px', marginTop: '5px'
                            }}>This field is required!</p>}
                        {this.state.valueSaved != '' && this.state.valueSaved != null && this.renderErrorMsg()}<div>
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}>
                                When do you want to start this goal?
                            </Typography>
                            {this.renderBackDrop()}
                            {this.renderForm(shouldSelectEmptyDate)}

                            {(this.state.formattedDateInter !== 'ok' && this.state.calendarOpen=='other' && !doesMatchFirstFive || !doesMatchFirstFive&& this.state.calendarOpen=='other' ) ? (
   <div style={{ position: "relative", zIndex: 2, display: 'flex', alignItems: 'center', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
    <input
      type="text"
      onClick={() => this.openPopUp(1)}
      data-test-id="otherinput"
      placeholder="Choose Day"
      value={this.state.formattedDateInter || ''}
      style={{
        width: '100%',
        fontFamily: 'Chromatica-Regular',
        fontSize: '16px',
        padding: '10px 0',
        border: 'none',
        outline: 'none',
        marginRight: '10px',
        backgroundColor: 'transparent',
      }}
      readOnly
    />
    <img
      data-test-id="otherimage"
      onClick={() => this.openPopUp(1)}
      src={calendar.default}
      alt="Open Calendar"
      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
    />
  </div>
) : null}

                            {this.state.checkAllValues && (this.state.formattedDateInter === '' || this.state.formattedDateInter === 'ok') && (
  <p
    style={{
      color: "#EB3E3E",
      fontFamily: "Chromatica-Regular",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      marginTop: '5px'
    }}
  >
    Date is required!
  </p>
)}
                        </div>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px'
                        }}
                        > At what time you need to be reminded?</Typography>
                        <div style={{ display: 'flex', alignItems: 'center',zIndex:'2' }}>
                        <div style={{ position: "relative", zIndex: 2, display: 'flex', alignItems: 'center', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                        type="text"
                                        data-test-id="timeropen"
                                        placeholder="Select Time"
                                         value={this.state.timeSet}
                                         onClick={()=>{this.openTimer()}}
                                        style={{
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            outline: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent'
                                           
                                        }}
                                        readOnly
                                    />
                                     <img
                                   src={time.default}
                                   onClick={()=>{this.openTimer()}}
                                    alt="Open Calendar"
                                    data-test-id="timerImageTestId"
                                    style={{ cursor: 'pointer', width: '24px', height: '24px',  zIndex: 10 }}
                                    
                                />
                                </div> 
                                {this.state.timeSet && (
                                <span style={{ marginLeft: '10px', marginTop:'20px',color: '#189718', fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>
                                    {`Great! We'll remind you at ${this.state.timeSet}`}
                                </span>
                            )}
                                </div>
                        {this.state.checkAllValues && !this.state.timeSet &&
                                    <p style={{
                                        color: "#EB3E3E",
                                        fontFamily: "Chromatica-Regular",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        lineHeight: "24px", marginTop: '5px'
                                    }}>Please enter a time for your reminder to continue.</p>}

                        <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupTime(this.state.openTime)}>
                            {this.state.openTime && (
                                <Card style={{
                                    display: 'flex', flexDirection: 'column',
                                    borderRadius: '28px', padding: '25px 10px 15px 10px',
                                    gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center',
                                }}
                                sx={otherStyles.card}>
                                    <CardContent style={{
                                        display: 'flex', flexDirection: 'column',
                                        gap: '35px', alignItems: 'center', height: "415px"
                                    }}>

                                    <TimeOtherGoals value={this.state.setTimeValue} onAccept={this.handleUserTime} onChange={this.handleDataChangeTime} navigation={undefined} id={''} onClose={this.handleClose}/>
                                    </CardContent>
                                </Card>
                            )}
                        </Backdrop>
                        <div style={{ marginTop:'20px',display: 'flex' }}>
                            <Checkbox
                            data-test-id="checkbox"
                                checked={this.state.setCheckbox || false}
                                onChange={() => this.handleCheckbox()}
                                sx={{
                                    marginBottom:'10px',
                                    width:'20px',
                                    height:'20px',
                                    borderRadius: '6px',
                                    color: this.handleColor(),
                                    '&.Mui-checked': {
                                        color: '#00A7D1',
                                    },
                                }}
                            />
                            <Typography sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginLeft:'10px',
                                zIndex: 10,
                                marginBottom: '20px'
                            }}
                            >  Do you want to make it happen on a daily basis?</Typography>
                        </div>
                        <Button
                        data-test-id="saveId"
                        onClick={()=>this.checkAllValuesData()}
                           variant="contained"
                           sx={{
                            ...OtherbuttonStyles,
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            },
                            lineHeight: {
                                xs: '18px',
                                sm: '20px',
                            },
                            marginTop: {
                                xs: '20px',
                                sm: '30px',
                            },
                            marginBottom: {
                                xs: '20px',
                                sm: '30px',
                            },
                            width: {
                                xs: '80%',
                                sm: '60%',
                                md: '348px',
                            },
                            height: {
                                xs: '48px',
                                sm: '56px',
                            },
                        }}
                    >
                      <span style={{ marginRight: '5px' }}>Next</span>
                      <img src={arrowright.default} />
                    </Button>
                        <Box
                            sx={{
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 4,
                            }}
                        >
                        </Box>
                    </Box>
                    
                    <Box
                                src={bottomBannerImg.default}
                                sx={{  
                                    width: '100%',
                                    bottom: 0,
                                    position: "absolute",
                                    height: 'auto',
                                    right: 0,                               
                                    maxWidth: '600px',
                                }}
                                alt="image" component="img"  
                              
                            />
                </Grid>
            </Grid>
            <Dialog
                PaperProps={{
                    style: {
                        width:391,
                        borderRadius: 24,
                        overflow:"hidden",
                    },
                }}
                open={this.state.goalPopup}
            >
                 {this.state.goalPopup && (
                                             <Confetti
                                                width={window.innerWidth}
                                                height={window.innerHeight}
                                                numberOfPieces={200}
                                                 recycle={false}
                                              />
                                               )}
                <DialogTitle>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center', 
                        }}
                    >
                        <Box
                            sx={{
                                width: '80px',
                                height: '80px',
                                marginBottom: '16px',
                                marginTop: "2rem", 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                boxShadow: '0px 0px 18px 0px #F3A622',
                                background: '#F3A622',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img
                                    style={{ textAlign: 'center' }}
                                    src={award_a6.default}
                                    alt="award_award"
                                />
                            </div>
                        </Box>

                        <Typography
                            style={{
                                fontFamily: 'Josefin Sans',
                                fontSize: '40px',
                                fontWeight: 700,
                                lineHeight: '40px',
                                letterSpacing: '-0.04em',
                                color:'#00A7D1',
                            }}
                        >
                            Congratulations!
                        </Typography>
                        <Box sx={{ overflowY: "auto", maxHeight: "120px", margin: "1.5rem 0", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <Typography
                            sx={{
                                fontFamily:'Chromatica-bold' ,
                                fontSize: '20px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                color:"#252525",
                            }}
                        >
                            You added a goal to {this.state.setValueInput}
                        </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Button
                                sx={{
                                    padding: "6px 10px",
                                    width: "281px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    border: "none",
                                    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                                data-test-id="otherGoalModal"
                                onClick={this.handleAddMoreGoal.bind(this)}
                            >
                                <Typography sx={{
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"white",
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    Add Another Goal <AddCircleOutlineIcon style={{ width:"24px",height:"24px" }}/>
                                </Typography>
                            </Button>
                            <Button
                                sx={{
                                    cursor: "pointer",
                                    padding: "6px 10px",
                                    width: "281px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    border: "none",
                                    textTransform: "none"
                                }}
                                onClick={this.handleDoneClick}
                                data-test-id="doneBtn"
                            >
                                <Typography sx={{
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"#3BA9D5",
                                    textAlign: "center",
                                }}>
                                    Done
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
            </Dialog>
            </>
        )
    }
}

const OtherbuttonStyles = {
    padding: {
        xs: '8px',
        sm: '10px',
        md: '12px',
    },
    borderRadius: "16px",
    boxShadow: 'none',
    background: "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
    textTransform: "none",
    backgroundColor: '#EFF8FB',
    fontWeight: 400,
    fontFamily: 'Chromatica-Bold',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const otherStyles = {
    card : {
        width: '320px',
       "@media (max-width: 414px)": {
                width: "280px",
        },
        "@media (min-width: 601px) and (max-width: 1280px)": {
                width: 'auto',
        },
        "@media (min-width: 800px) and (max-width: 1025px)": {
                width: 'auto',
        },
    },
    otherGridStylegoals: {
        backgroundColor: "#0F4257",
        borderRadius: "16px",
        height: "88px",
        position: "relative",
    },
    otherdivStylegoals: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        height: "100%",
        alignContent: "center",
    },
    othergapMgoals: {
        fontSize: "clamp(25px, 5vw, 40px)",
        color: "#ffffff",
        fontFamily: "Josefin Sans",
        fontWeight: 300,
    },
    otherparentWellgoals:{
        fontWeight: 700,
        marginBottom: "4px",
        color: "#EBFF00",
        fontSize: "clamp(25px, 5vw, 40px)",
        marginLeft: {
            xs: '17px',
            md: '48px',
        },
        fontFamily: "Josefin Sans",
        lineHeight: "40px",
    },
    typoText: {
        fontFamily: "Chromatica-Bold",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#FFFFFF",
        mt: "3px",
        fontSize: "clamp(14px, 2vw, 20px)",
    },
    hamburgerIcon: {
        position: "absolute",
        top: "50%",
        right: "0.9%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
    }
};